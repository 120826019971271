body {
  background-color: var(--primary-util);
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

/* === ANIMATIONS === */
@keyframes menuHeight {
  from {
    height: 0px;
    opacity: 0%;
  }
  to {
    height: var(--app-height);
    opacity: 100%;
  }
}

@keyframes menuWidth {
  from {
    width: 78px;
  }
  to {
    width: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

@keyframes grow {
  from {
    opacity: 0%;
    transform: scale(0);
  }
  to {
    opacity: 100%;
    transform: scale(1.0);
  }
}

/* === ROOT === */
:root {
 --app-height: 100%;
 --maroon: #C01F45;
 --pink: #FB1E53;
 --primary-util: #E7E5D9;
 --secondary-util: #AB8570;
}

p {
  font-family: "degular-variable",sans-serif;
  font-variation-settings: 'ital' 0, 'opsz' 6, 'wght' 200;
}

h1 {
  font-family: "degular-variable",sans-serif;
  font-weight: 800;
}

h3 {
  font-family: "degular-variable",sans-serif;
}

li {
  font-family: "degular-variable",sans-serif;
}

/* === NAVIGATION === */
.nav-wrapper {
  animation: menuHeight 600ms ease-in-out 0ms 1 forwards;
  background-color: var(--maroon);
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  height: 0px;
  justify-content: space-between;
  margin-left: 20px;
  opacity: 0%;
  overflow: hidden;
  position: fixed;
  transition-duration: 200ms;
  width: calc(100vW / 7);
}

.nav-wrapper p {
  color: var(--primary-util);
  margin: 0px 0px 5px 0px;
}

.footer-wrapper p {
  color: var(--primary-util);
  margin: 0px 0px 5px 0px;
}

.bottom-nav-container > a {
  color: var(--maroon);
  transition-duration: 300ms;
}



.top-nav-container {
  padding: 10px;
}

.bottom-nav-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.social-icon {
  fill: var(--primary-util);
  height: 20px;
  margin-right: 5px;
  transition-duration: 300ms;
  max-width: 22px;
}


.cls-1 {
  fill: none;
  stroke-width: 3px;
}

.cls-1, .cls-2 {
  stroke: var(--primary-util);
  stroke-miterlimit: 10;
}

.cls-2 {
  fill: var(--primary-util);
}

#instagram {
  animation: fadeIn 300ms ease-in-out 600ms 1 forwards;
  opacity: 0%;
  transition-duration: 300ms;
}

#newsletter {
  animation: fadeIn 300ms ease-in-out 900ms 1 forwards;
  opacity: 0%;
  margin-bottom: 0px;
  transition-duration: 300ms;
}

/* === SUBNAV === */
.subnav-wrapper {
  align-items: center; 
  animation: fadeIn 300ms ease-in-out 1 forwards;
  animation-delay: 5800ms;
  box-sizing: border-box;
  border-radius: 50px;
  display: flex;
  height: 80px;
  justify-content: flex-start;
  padding: 10px 20px;
  opacity: 0%;
  position: fixed;
  top: 10px;
  width: 60%;
}

.subnav-link-container {
  display: flex;
  column-gap: 20px;
}

.subnav-link-container > a {
  color: var(--pink);
  transition-duration: 300ms;
}

.subnav-link-container > a > h3 {
  color: var(--primary-util)
}

.subnav-glyph-container {
  align-items: center;
  background-color: var(--pink);
  animation-timing-function: ease-in-out;
  outline-style: solid;
  outline-color: var(--primary-util);
  outline-width: 4px;
  border-radius: 50px;
  box-sizing: border-box;
  display: flex;
  height: 80px;
  justify-content: center;
  left: -1px;
  position: absolute;
  transition-duration: 300ms;
  width: 80px;
}

.subnav-glyph {
  fill: var(--primary-util);
  height: 50px;
  width: 50px;
}



#subnav {
  align-items: center;
  /* animation: menuWidth 300ms ease-in-out 1 both;
  animation-delay: 6000ms; */
  background-color: var(--pink);
  display: flex;
  border-radius: 40px;
  height: 80px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition-duration: 300ms;
  width: 10%;
}

/* === PAGES === */
.page-wrapper {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: 0px;
  padding: 20px;
  overflow: scroll;
  width: calc(100vw / 7 * 6);
}

.section-wrapper {
  box-sizing: border-box;
  height: var(--app-height);
  padding: 0px 50px;
  scroll-snap-stop: normal;
  scroll-snap-type: y mandatory;
  width: 100%;
}

/* === WHISTLESPACE === */
.intro-section {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.whistle-space-wordmark {
  width: 50%;
}

.whistle-space-wordmark > path {
  /* animation: fadeIn 300ms ease-in-out 1 forwards; */
  fill: var(--pink);
}

.whistle-space-wordmark-animation {
  animation: fadeIn 300ms ease-in-out 1 forwards;
}

.whistle-space-wordmark-animation-one {
  animation: fadeIn 2000ms ease-in-out 1 forwards;
  animation-delay: 1800ms;
  opacity: 0%;
}

.whistle-space-wordmark-animation-two {
  animation: fadeIn 2000ms ease-in-out 1 forwards;
  animation-delay: 2400ms;
  opacity: 0%;
}

.whistle-space-wordmark-animation-three {
  animation: fadeIn 2000ms ease-in-out 1 forwards;
  animation-delay: 2950ms;
  opacity: 0%;
}

.whistle-space-wordmark-animation-four {
  animation: fadeIn 2000ms ease-in-out 1 forwards;
  animation-delay: 3450ms;
  opacity: 0%;
}

.whistle-space-wordmark-animation-five {
  animation: fadeIn 2000ms ease-in-out 1 forwards;
  animation-delay: 3900ms;
  opacity: 0%;
}

.whistle-space-wordmark-animation-six {
  animation: fadeIn 2000ms ease-in-out 1 forwards;
  animation-delay: 4300ms;
  opacity: 0%;
}

.whistle-space-wordmark-animation-seven {
  animation: fadeIn 2000ms ease-in-out 1 forwards;
  animation-delay: 4650ms;
  opacity: 0%;
}

.whistle-space-wordmark-animation-eight > path {
  animation: fadeIn 1000ms ease-in-out 1 forwards;
  animation-delay: 4900ms;
  fill: var(--pink);
  /* transform: scale(0);
  transform-origin: 50% 100%; */
  opacity: 0%;
}

.intro-button-link {
  bottom: 50px;
  position: fixed;
  z-index: 10;
  transition-duration: 300ms;
}



.cta-button {
  background-color: var(--pink);
  border-radius: 5px;
  border: none;
  color: var(--primary-util);
  padding: 0px 50px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}



.cta-button:active {
  box-shadow: 0px 4px 4px 0px rgba(251, 30, 83, 0.5);

}

.text-section {
  width: 50%;
}

.scroll-margin {
  scroll-margin-top: 25vH;
}

.footer-wrapper {
  display: none;
}

@media screen and  (max-width: 900px) {
  .nav-wrapper {
    display: none;
  }
  
  .footer-wrapper {
    background-color: var(--maroon);
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    height: var(--app-height);
    justify-content: center;
    opacity: 100%;
    overflow: hidden;
    scroll-snap-stop: normal;
    scroll-snap-type: y mandatory;
    width: 100%;
  }

  .top-nav-container {
    padding: 20px;
  }

  .bottom-nav-container {
    padding: 20px;
  }

  .subnav-wrapper {
    width: 100%;
    height: 60px;
  }

  #subnav {
    height: 60px;
  }

  .subnav-glyph-container {
    height: 60px;
    width: 60px;
  }

  .whistle-space-wordmark {
    width: 80%;
  }

  .subnav-glyph {
    height: 40px;
    width: 40px;
  }

  .page-wrapper {
    padding: 0;
    width: 100%;
  }

  .section-wrapper {
    padding: 20px;
  }

  .text-section {
    width: 80%;
  }
}

@media (hover: hover) {
  .social-icon:hover {
    cursor: pointer;
    /* fill: black; */
    transition-duration: 300ms;
    transform: rotate(90deg);
  }

  .subnav-glyph-container:hover {
    cursor: pointer;
    transform: rotate(45deg);
    transition-duration: 300ms;
  }

  .bottom-nav-container > a:hover {
    color: var(--primary-util);
    transition-duration: 300ms;
  }

  .subnav-link-container > a:hover {
    color: var(--primary-util);
    transition-duration: 300ms;
  }

  .intro-button-link:hover {
    cursor: pointer;
    bottom: 47px;
    transition-duration: 200ms;
  }
  
  .subnav-glyph:hover {
    cursor: pointer;
  }

  .cta-button:hover {
    cursor: pointer;
  }
}